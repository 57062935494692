"use client";

import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { SessionProvider } from "next-auth/react";
import { ContextProviders } from "../../context";

const queryClient = new QueryClient();

export default function ClientContext({ children }) {
  return (
    <SessionProvider>
      <ContextProviders>
        <QueryClientProvider client={queryClient}>
          {children}
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </ContextProviders>
    </SessionProvider>
  );
}
