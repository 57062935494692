"use client";

import { useEffect } from "react";
import { logError } from "@lib/logger";
import { Component } from "react";
import Script from "next/script";
import { useRouter } from "next/navigation";
import { GA4_TRACKING_ID, GA_TRACKING_ID, GTM_TRACKING_ID } from "@lib/gtag";
import { FB_PIXEL_ID } from "@lib/facebookPixel";
import { PINT_TRACKING_ID } from "@lib/pinterest";
import { TIKTOK_PIXEL_ID } from "@lib/tiktokPixel";

function setDiscountCode(router) {
  if (router?.query?.dc) {
    window.discountCode = router?.query?.dc;
  }
}

export function ClientComponent() {
  const router = useRouter();

  useEffect(() => {
    // Find the element by ID
    const timer = setTimeout(() => {
      const element = document.getElementById('CookiebotWidget');
      if (element) {
        element.style.display = 'none';
        return () => clearTimeout(timer);
      }
    }, 3000);
  }, []);

  setDiscountCode(router);

  return (
    <>
      <link
        rel="preload"
        href="https://cdn.depict.ai/kotn/modern.js"
        as="fetch"
        crossOrigin="anonymous"
      />;
      <noscript
        dangerouslySetInnerHTML={{
          __html: `<img height="1" width="1" style="display:none" 
                      src="https://www.facebook.com/tr?id=${FB_PIXEL_ID}&ev=PageView&noscript=1"/>`,
        }}
      />
      <noscript
        dangerouslySetInnerHTML={{
          __html: `<img height="1" width="1" style="display:none;" alt=""
              src="https://ct.pinterest.com/v3/?event=init&tid=${PINT_TRACKING_ID}&pd[em]=<hashed_email_address>&noscript=1" />`,
        }}
      />

      <Script
        id="gorgias-chat-widget-install-v3"
        src="https://config.gorgias.chat/bundle-loader/01GYCCA8M1JTWEBS9H6Y67ETFY"
      ></Script>

      {/* Good on you Pixel */}
      <Script type="text/javascript" src="https://www.km0trk.com/scripts/sdk/everflow.js" />
      <Script
        id="good-js"
        dangerouslySetInnerHTML={{
          __html: `
                EF.click({
                  offer_id: EF.urlParameter('oid'),
                  affiliate_id: EF.urlParameter('affid'),
                  sub1: EF.urlParameter('sub1'),
                  sub2: EF.urlParameter('sub2'),
                  sub3: EF.urlParameter('sub3'),
                  sub4: EF.urlParameter('sub4'),
                  sub5: EF.urlParameter('sub5'),
                  uid: EF.urlParameter('uid'),
                  source_id: EF.urlParameter('source_id'),
                  transaction_id: EF.urlParameter('_ef_transaction_id'),
                });
              
            `,
        }}
      />
      {/* Global Site Tag (gtag.js) - Google Analytics */}
      <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`}
      />

      <Script
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${GTM_TRACKING_ID}');`,
        }}
      ></Script>
      <Script
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
              (function(d) {
                var e = d.createElement('script');
                e.src = d.location.protocol + '//tag.wknd.ai/7423/i.js';
                e.async = true;
                d.getElementsByTagName("head")[0].appendChild(e);
              }(document));
            `,
        }}
      ></Script>
      <Script
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: ` 
          var ALGOLIA_INSIGHTS_SRC = "https://cdn.jsdelivr.net/npm/search-insights@4.7.0/dist/search-insights.min.js";
          !function(e,a,t,n,s,i,c){e.AlgoliaAnalyticsObject=s,e[s]=e[s]||function(){
          (e[s].queue=e[s].queue||[]).push(arguments)},i=a.createElement(t),c=a.getElementsByTagName(t)[0],
          i.async=1,i.src=n,c.parentNode.insertBefore(i,c)
          }(window,document,"script",ALGOLIA_INSIGHTS_SRC,"aa");`,
        }}
      ></Script>

      <Script
        id="gtag-js"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
          urlSearchParams = new URLSearchParams(window.location.search);
          params = Object.fromEntries(urlSearchParams.entries());

          function getFbpCookie() {
            try {
               return /_fbp=(fb\.1\.\d+\.\d+)/.exec(window.document.cookie)[1];
            } catch (e) {
               return undefined;
            }
          }

          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', '${GA_TRACKING_ID}', {
            send_page_view: false
          });

          gtag('config', '${GA4_TRACKING_ID}', {
            send_page_view: false,
            fbclid: params["fbclid"],
            fbp: getFbpCookie()
          });
          `,
        }}
      />
      <Script
        src="https://www.googleoptimize.com/optimize.js?id=GTM-N7FXHL2"
        strategy="afterInteractive"
      />

      {/* Global Site Tag (share a sale.js) - Share a Sale */}
      <Script src="https://www.dwin1.com/19038.js" strategy="afterInteractive" />
      {/* Global Site Tag (klaviyo.js) - Klaviyo */}
      <Script
        src="https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=LMZu4j"
        strategy="afterInteractive"
      />
      <Script
        id="klaviyo-js"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
              window._learnq = window._learnq || [];
              function klaviyo(arguments){_learnq.push(arguments);}
          `,
        }}
      />
      {/* Global Site Tag (grin-sdk.js) - Grin */}
      <Script src="https://d38xvr37kwwhcm.cloudfront.net/js/grin-sdk.js" strategy="lazyOnload" />
      <Script
        id="grin-js"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
          Grin = window.Grin || (window.Grin = []);
          `,
        }}
      />
      {/* Global Site Tag (bat.bing.com/bat.js) - Bing */}
      <Script
        id="bing-js"
        dangerouslySetInnerHTML={{
          __html: `
              (function(w,d,t,r,u){var f,n,i;w[u]=w[u]||[],f=function(){var o={ti:"134083254"};o.q=w[u],w[u]=new UET(o),w[u].push("pageLoad")},n=d.createElement(t),n.src=r,n.async=1,n.onload=n.onreadystatechange=function(){var s=this.readyState;s&&s!=="loaded"&&s!=="complete"||(f(),n.onload=n.onreadystatechange=null)},i=d.getElementsByTagName(t)[0],i.parentNode.insertBefore(n,i)})(window,document,"script","//bat.bing.com/bat.js","uetq");
            `,
        }}
        strategy="afterInteractive"
      />
      {/* Global Site Tag - Referral */}
      <Script src="https://my.kotn.com/core.js" strategy="afterInteractive" />
      <Script
        id="extole-js"
        dangerouslySetInnerHTML={{
          __html: `
                      (function (c, e, k, l, a) {
                        c[e] = c[e] || {};
                        for (c[e].q = c[e].q || []; a < l.length; ) k(l[a++], c[e]);
                      })(
                        window,
                        "extole",
                        function (c, e) {
                          e[c] =
                            e[c] ||
                            function () {
                              e.q.push([c].concat([].slice.call(arguments, 0)));
                            };
                        },
                        ["createZone", "createConversion", "createLead"]
                      );
                      window.extole.createZone({
                        name: "sidebar",
                        element_id: "extole_zone",
                        data: { key1: "value1", key2: "value2" },
                      });
                      window.extole.createZone({ name: "footer" });
          `,
        }}
        strategy="afterInteractive"
      />
      {/* Pinterest Tag */}
      <Script
        id="pinterest-js"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
          !function(e){if(!window.pintrk){window.pintrk = function () {
          window.pintrk.queue.push(Array.prototype.slice.call(arguments))};
          var
          n=window.pintrk;n.queue=[],n.version="3.0";var
          t=document.createElement("script");t.async=!0,t.src=e;var
          r=document.getElementsByTagName("script")[0];
          r.parentNode.insertBefore(t,r)}}("https://s.pinimg.com/ct/core.js");
          pintrk('load', '${PINT_TRACKING_ID}', {em: '<user_email_address>'});
          pintrk('page');
          `,
        }}
      />
      {/* TikTok Pixel */}
      <Script
        id="tiktok-pixel"
        dangerouslySetInnerHTML={{
          __html: `
          !function (w, d, t) {
              w.TiktokAnalyticsObject = t;
              var ttq = (w[t] = w[t] || []);
              ttq.methods = [
                "page",
                "track",
                "identify",
                "instances",
                "debug",
                "on",
                "off",
                "once",
                "ready",
                "alias",
                "group",
                "enableCookie",
                "disableCookie",
              ];
              ttq.setAndDefer = function (t, e) {
                t[e] = function () {
                  t.push([e].concat(Array.prototype.slice.call(arguments, 0)));
                };
              };
              for (var i = 0; i < ttq.methods.length; i++)
                ttq.setAndDefer(ttq, ttq.methods[i]);
              ttq.instance = function (t) {
                for (var e = ttq._i[t] || [], n = 0; n < ttq.methods.length; n++)
                  ttq.setAndDefer(e, ttq.methods[n]);
                return e;
              };
              ttq.load = function (e, n) {
                var i = "https://analytics.tiktok.com/i18n/pixel/events.js";
                (ttq._i = ttq._i || {}),
                  (ttq._i[e] = []),
                  (ttq._i[e]._u = i),
                  (ttq._t = ttq._t || {}),
                  (ttq._t[e] = +new Date()),
                  (ttq._o = ttq._o || {}),
                  (ttq._o[e] = n || {});
                var o = document.createElement("script");
                (o.type = "text/javascript"),
                  (o.async = !0),
                  (o.src = i + "?sdkid=" + e + "&lib=" + t);
                var a = document.getElementsByTagName("script")[0];
                a.parentNode.insertBefore(o, a);
              };
              ttq.load("${TIKTOK_PIXEL_ID}");
              ttq.page();
            })(window, document, "ttq");
          `,
        }}
        strategy="afterInteractive"
      />
    </>
  );
}

export class MyErrorBoundary extends Component {
  state = {
    errorMessage: "",
  };

  static getDerivedStateFromError(error) {
    return { errorMessage: error.toString() };
  }

  componentDidCatch(error, info) {
    this.logErrorToServices(error.toString(), info.componentStack);
  }

  logErrorToServices = logError;

  render() {
    if (this.state.errorMessage) {
      return <Error statusCode={500} title="An unexpected error occurred!" />;
    }
    return this.props.children;
  }
}
